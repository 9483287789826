<template>
    <div class="flex_box" style="">
        <top></top>
        <nav-bar></nav-bar>
        <list-nei></list-nei>
        <qrcodeBar></qrcodeBar>
    </div>
</template>

<script>
    import NavBar from "../../components/common/NavBar";
    import Top from "../../components/common/top";
    import listNei  from "./base/listNei";
    import qrcodeBar from "../../components/qrcode";
    export default {
        data(){
            return {
                username: '',
                portrait:require('../../assets/img/home/d_tp.png'),
                showMe:false,
                courseUrl:'',
                roleId:1
            }
        },
        methods:{
            tui(){
                localStorage.setItem('loginStatus',null);
                this.$router.push({
                    path:'/home'
                })
            },
        },
        name: "printList",
        mounted(){
            let info = JSON.parse(localStorage.getItem('userinfo'));
            this.roleId = info.register_role_id;
            if(info.username){
                this.username = info.username;
            }
            if(info.portrait){ 
                this.portrait = require(info.portrait);
            }
        },
        components:{
            listNei,
            NavBar,
            Top,
            qrcodeBar
        },
    }

</script>
import apis from "../../common/js/api2";
// import { printList, rmTpl, shareConfirm } from "../../common/js/api";
import tools from "../../../extends/tools";
import XLSX from 'xlsx';
export default {
    createShare(id) {
        this.currentId = id;
        this.createShareFlag = true;
    },
    cainiaoTpl() {
        this.$router.push({
            path: '/cainiao/lists'
        });
    },
    showShareList(id) {
        this.currentId = id;
        this.shareListFlag = true;
    },
    importShareCode() {
        this.$prompt('请输入分享码', '导入分享码', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(({ value }) => {
            apis.shareConfirm({
                code: value
            }).then((res) => {
                if (res.code != 200) {
                    this.$message(res.msg);
                    return;
                }
                this.$message({
                    type: 'success',
                    message: '导入成功！'
                });
                this.getList();
            }).catch((error) => {
                this.$message('网络或程序错误，请稍候重试！' + error);
            });
        }).catch((err) => {
            console.log(err);
            this.$message({
                message: '操作取消'
            });
        });
    },
    getList() {
        apis.printList(this.req).then((res) => {
            res.data.lists.forEach(element => {
                element.text_source = JSON.parse(element.text_source);
            });
            this.tableData = res.data.lists;

            this.total = res.data.count;
        }).catch((err) => {
            console.log(err);
            this.$message('网络或程序错误，请稍候重试！', 'error');
        })
    },
    remove(id, key) {
        this.$confirm('确定删除此销售单?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.rmTpl({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                } else {
                    this.$message({
                        type: 'success',
                        message: info.msg
                    });
                }
            })
        }).catch((error) => {
            console.log(error);
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    //导入Excel打印
    printExcel(e, id, source) {
        // console.log(typeof source);
        this.currentId = id;
        this.printAction = 'simple2';
        this.textSource = source;
        this.confirmExcel = true;
    },
    // 直接打印
    printStatic(e, id) {
        this.currentId = id;
        this.printAction = 'simple1';
        this.textSource = JSON.parse('[]');
        this.printFlag = true;
    },
    // 开始打印，组装数据
    printAll() {
        localStorage.setItem('selectFileData', JSON.stringify(this.selectFileData));
        this.printFlag = true;
        this.printAction = 'simple2';
    },
    printLodopExit() {
        this.printFlag = false;
    },
    shareExit() {
        this.createShareFlag = false;
    },
    //编辑模版
    edit(e, id) {
        this.$router.push({
            path: '/editor?id=' + id
        })
    },
    deletew() {
        this.$confirm('确定删除此销售单?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.$message({
                type: 'success',
                message: '删除成功!'
            });
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });

    },
    onSubmit() {
        // console.log(this.req);
        this.getList();
    },
    onReset() {
        // console.log("重置!");
    },
    handleSizeChange(val) {
        this.req.limit = val;
        this.getList();
    },
    handleCurrentChange(val) {
        this.req.page = val;
        this.getList();
    },
    //导入Excel
    // 获取文件
    change_file(e) {
        //自定义单据名称
        if (this.$refs.file && !this.$refs.file.files[0]) {
            return false
        };
        const selected_file = this.$refs.file.files[0];
        this.fileExcel.oldFile = selected_file;
        this.file_name = selected_file.name;
        this.file_size = selected_file.size ? (selected_file.size / 1024).toFixed(2) : 0;
        this.readFule(selected_file)
    },
    readFule(file) {
        var name = file.name;
        var reader = new FileReader();
        reader.onload = (e) => {
            //重置file
            this.clearExcel = false;
            //重置表头数组
            this.textSource2 = [];
            this.fileExcel.oldFile = e.target.result;
            let wb = XLSX.read(this.fileExcel.oldFile, { type: "binary" });
            const wb_sheet = wb.Sheets[wb.SheetNames[0]];

            this.selectFileData = XLSX.utils.sheet_to_json(wb_sheet);

            if (!this.selectFileData.length) {
                this.$alert('数据行不得为空，请添加数据后上传', '错误警告', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.clearExcel = true;
                    }
                });
                return false
            } else {
                //更新数据源
                let tmp = this.selectFileData[0];
                for (var index in tmp) {
                    //创建textSource数据源
                    let tmp = {
                        'label': index,
                        'value': '$$' + index + '$$'
                    };
                    this.textSource2.push(tmp);
                }
                this.uploadStatus = true;
                this.tpl_eq_excel = (this.textSource2.length === this.textSource.length);

                if (this.tpl_eq_excel) {
                    for (var i = 0; i < this.textSource2.length; i++) {
                        if (this.textSource2[i]['name'] !== this.textSource[i]['name']) {
                            this.tpl_eq_excel = false;
                            break;
                        }
                    }
                }

                this.clearExcel = true;
            }
        };
        reader.readAsBinaryString(file);
    }
}
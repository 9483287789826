<template>
  <div>
       <!-- 创建 -->
    <el-table
            ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
            :data="tableData"
            stripe
            tooltip-effect="dark"
            style="width: 96%;text-align: center">
        <el-table-column align="center" type="selection" width="65 "> </el-table-column>

        <el-table-column label="ID"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.id }}</template>
        </el-table-column>

        <el-table-column label="截止时间"  min-width="65" align="center">
            <template slot-scope="scope">
                <span  v-if="scope.row.max_effective_time != 1">{{scope.row.effective_time}}</span>
                <span  v-if="scope.row.max_effective_time == 1">永久有效</span>
            </template>
        </el-table-column>

        <el-table-column label="分享次数"  min-width="65" align="center">
            <template slot-scope="scope">
                <span  v-if="scope.row.max_share_times != 1">{{scope.row.share_times}}</span>
                <span  v-if="scope.row.max_share_times == 1">无限制</span>
            </template>
        </el-table-column>
        <el-table-column label="创建时间"  min-width="65" align="center">
            <template slot-scope="scope">{{ scope.row.create_time }}</template>
        </el-table-column>
        <el-table-column label="状态"  min-width="55" align="center">
            <template slot-scope="scope">
                <div class="status_success" v-if="scope.row.status == 1">分享中</div>
                <div class="status_error" v-if="scope.row.status == 2">禁用中</div>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
                <el-button type="text" size="small"  @click="remove(scope.row.id)">
                    删除
                </el-button>
                <el-button type="text" size="small"  @click="update(scope.row.id)">
                    查看/编辑
                </el-button>

            </template>
        </el-table-column>
    </el-table>
    <!-- 页码 -->
    <div class="block" >
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
        </el-pagination>
    </div>
    <div style="height:60px;"></div>

    <!-- 创建分享 -->
    <el-dialog
        :modal-append-to-body="false"
    title="创建分享"
    :visible.sync="createShareFlag"
    v-if="createShareFlag"
    :modal="false"
    width="60%"
    center>
        <createShare style="height:550px;" :printId="printId" :shareId="shareId" @fleshList="getList"></createShare>
    </el-dialog>

  </div>
</template>

<script>
import {shareList,shareDel } from "../../common/js/api";
import tools from "../../../extends/tools";
import createShare from '../share/creatShare';

export default {
    props:{
        printId:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            createShareFlag:false,
            tableData:[],
            total:0,
            currentPage:1,
            shareId:0,
            req:{
                limit:30,
                page:1
            }
        }
    },
    methods:{
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            this.req.headers = tools.getToken();
            this.req.id = this.printId;

            shareList(this.req).then((res) => {
                this.tableData = res.data.data;
                console.log(this.tableData);
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                shareDel({ id: id, headers: tools.getToken() }).then((info) => {
                    if (info.code == 200) {
                        this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: info.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        update(id){
            this.createShareFlag = true;
            this.shareId = id;
        }
    },
    created(){
        this.getList();
    },
    components:{
        createShare
    }
}
</script>
<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>
export default {
    // 打印标志
    printFlag: false,
    printFlag2: false,
    printAction: 'simple1',
    //分享标志
    createShareFlag: false,
    //分享列表
    shareListFlag: false,
    tplId: 0,
    statusList: [{
            value: 1,
            label: "正常",
        },
        {
            value: 2,
            label: "禁用",
        }
    ],
    value: "",
    data: "",
    tableData: [],
    currentPage: 0,
    confirmExcel: false,
    total: 0,
    //打印表头
    textSource: [],
    textSource2: [],
    //上传excel
    clearExcel: true,
    fileExcel: {},
    file_name: '',
    file_size: 0,
    selectFileData: [],
    //导入的excel和模版是否匹配
    tpl_eq_excel: true,
    uploadStatus: false,
    sourceData: [],
    //当前操作的对象id
    currentId: 0,
    currentTpl: {},
    printAllLoading: false,
    roleId: 1,
    // 请求参数
    req: {
        page: 1, //当前页
        limit: 10, //每次请求条数
        enable_status: 1,
        start: '',
        end: '',
        keyword: ''
    }

}
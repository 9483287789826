<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <el-card class="box-card">
                <!-- 按钮 -->
                <el-row style="height:40px;">
                    <router-link to="/editor"><el-button type="primary" class="buttons">新增模版</el-button></router-link>
                    <span style="display:inline-block;width:10px;"></span>    
                    <el-button type="success" class="buttons" style="width:110px;" @click="importShareCode">分享码导入</el-button>
                    <el-button type="primary" class="buttons" style="width:110px;" @click="cainiaoTpl">菜鸟模板管理</el-button>
                </el-row>
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" style="display:none;">
                    <el-form-item label="状态" >
                        <el-select v-model="req.enable_status" placeholder="请选择" style="width:120px;">
                            <el-option
                                    v-for="item in statusList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 00:00:00"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.end"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd 23:59:59"
                                type="date"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                                placeholder="请输入关键词搜索"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                        <!-- <el-button @click="onReset">重置</el-button> -->
                    </el-form-item>
                </el-form>
                <!-- 表格 -->
                <el-table
                        ref="multipleTable"
                        :height="__tableHeight__"
        
        
        
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width: 96%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="模版名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.names }}</template>
                    </el-table-column>
                    <el-table-column label="来源"  min-width="65" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_from_shara > 0">导入分享码</span>
                            <span v-if="scope.row.is_from_shara == 0">自定义创建</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="模版类型"  min-width="65" align="center">
                        <template slot-scope="scope" >
                            <span v-if="scope.row.type === 0">
                                <span v-if="scope.row.text_source.length == 0">单张打印模版</span>
                                <span v-if="scope.row.text_source.length > 0 ">连续打印模版</span>
                            </span>
                            <span v-if="scope.row.type === 1">发货单</span>
                            <span v-if="scope.row.type === 2">备货单</span>
                            <span v-if="scope.row.type === 3">快递单</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="分享"  min-width="65" align="center">
                        <template slot-scope="scope">
                            &nbsp;&nbsp;
                            <el-button type="text" size="small" @click="createShare(scope.row.id)">
                                创建分享
                            </el-button>
                            <el-button type="text" size="small" @click="showShareList(scope.row.id)">
                                分享记录({{scope.row.usesharetimes_info_count}}条)
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.create_time }}</template>
                    </el-table-column>
                    <el-table-column label="状态"  min-width="55" align="center">
                        <template slot-scope="scope">
                            <div class="status_success" v-if="scope.row.enable_status == 1">正常</div>
                            <div class="status_error" v-if="scope.row.enable_status == 2">禁用</div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 0" >
                            <el-button type="text" size="small" v-if="scope.row.text_source.length == 0" @click="printStatic($event,scope.row.id)">
                                直接打印
                            </el-button>
                            <el-button v-if="scope.row.text_source.length > 0" type="text" size="small" @click="printExcel($event,scope.row.id,scope.row.text_source)">
                                Excel打印
                            </el-button>
                            </span>
                            <!-- <el-button type="text" size="small">
                                数据管理
                            </el-button> -->
                            <el-button type="text" size="small" @click="edit($event,scope.row.id)" style="margin-left:8px;">
                                编辑
                            </el-button>
                            <el-button type="text" size="small"  @click="remove(scope.row.id)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 页码 -->
                <div class="block">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="req.page"
                            :page-size="req.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            class="flex-right">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <!-- 确认表格表头 -->
        <el-dialog
        :modal-append-to-body="false"
        title="请根据模版表头上传对应的Excel文件"
        :visible.sync="confirmExcel"
        width="80%"
        v-loading="printAllLoading"
        center>
            <el-row style="text-align:center;font-size:16px;height:40px;line-height:40px;color:blue;">保存的模版表头</el-row>
            <el-row class="danju_row" style="width:100%">
                    <el-table  border align="center">
                    <el-table-column v-for="(item,key) in textSource" :key="key"
                        :label="item.label">
                    </el-table-column>
                    </el-table>
            </el-row>

            <el-row style="text-align:center;font-size:16px;height:40px;line-height:40px;color:blue;">导入的Excel表头 - 
                <span v-if="!uploadStatus " style="color:blue;">待导入</span>
                <span v-if="uploadStatus && tpl_eq_excel" style="color:green;">表头相同，可以开始打印</span>
                <span v-if="uploadStatus && !tpl_eq_excel" style="color:red;">表头异常,请重新导入</span>
            </el-row>

            <el-row class="danju_row" style="width:100%" v-if="textSource2.length>0">
                    <el-table  border align="center">
                        <el-table-column v-for="(item,key) in textSource2" :key="key"
                            :label="item.name">
                        </el-table-column>
                    </el-table>
            </el-row>
            <el-row style="text-align:center;font-size:16px;height:40px;line-height:40px;color:blue;"> 
                共导入 <b style="color:#f00;font-size:18px;">{{selectFileData.length}} </b> 行数据
            </el-row>

            <div class="djaction" style="position:relative;text-align:center;margin-top:30px;">
                <el-button type="primary" size="small" style="font-weight:600">导入Excel<i class="el-icon-upload2 el-icon--right"></i></el-button>
                <div id="excel_input"><input type="file" class="filexls" ref="file" name="file" accept=".xlsx,.xls" @change="change_file" v-if="clearExcel"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="clearExcel=false;confirmExcel=false;">取消打印</el-button>
                <el-button type="primary" @click="printAll" v-if="uploadStatus && tpl_eq_excel">全部打印</el-button>
            </span>
        </el-dialog>

        <!-- 打印 -->
        <el-dialog
        :modal-append-to-body="false"
        title="直接打印"
        :visible.sync="printFlag"
        v-if="printFlag"
        :destroy-on-close="true"
        width="60%"
        center>
            <printStatic v-if="printFlag" :printAction="printAction" style="height:550px;" :printId="currentId" @printLodopExit="printLodopExit"></printStatic>
        </el-dialog>

        <el-dialog
        :modal-append-to-body="false"
        title="连续打印"
        :visible.sync="printFlag2"
        v-if="printFlag2"
        :destroy-on-close="true"
        width="60%"
        center>
            <printStatic v-if="printFlag2" :printAction="printAction" style="height:550px;" :printId="currentId" @printLodopExit="printLodopExit"></printStatic>
        </el-dialog>


        <!-- 创建分享 -->
        <el-dialog
        :modal-append-to-body="false"
        title="创建分享"
        :visible.sync="createShareFlag"
        v-if="createShareFlag"
        width="60%"
        center>
            <createShare style="height:550px;" :printId="currentId" @fleshList="getList" @shareExit="shareExit"></createShare>
        </el-dialog>

        <!-- 分享列表 -->
        <el-dialog
        :modal-append-to-body="false"
        title="分享列表"
        :visible.sync="shareListFlag"
        v-if="shareListFlag"
        width="80%"
        center>
            <shareList :printId="currentId" ></shareList>
        </el-dialog>


    </div>
</template>

<script>
import data from "./objectData.js";
import method from "./method.js";
import printStatic from '../printStatic.vue';
import createShare from '../share/creatShare';
import shareList from '../share/shareList';

export default {
    name: "ListNei",
    inject: ['selectSide'], 
    data() {
        return data;
    },
    created() {
        this.getList();
        let info = JSON.parse(localStorage.getItem('userinfo'))
        this.roleId = info.register_role_id;
    },
    methods:method,
    components:{
        printStatic,createShare,shareList
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>